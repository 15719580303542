<template>
  <main class="page">
    <Plans :useFullPrice="useFullPrice" :useTrialsAsTitle="useTrialsAsTitle" />
    <Purchase v-if="!$store.state.user.hasSubscription" />
    <UserHasSubscription v-if="$store.state.user.hasSubscription" />
  </main>
</template>

<script>
import Plans from './sections/Plans'
import Purchase from './sections/Purchase'
import UserHasSubscription from './sections/UserHasSubscription'

export default {
  name: 'Prices',
  components: {
    UserHasSubscription,
    Plans,
    Purchase,
  },
  data() {
    return {
      useFullPrice: false,
      useTrialsAsTitle: false,
    }
  },
  beforeMount() {
    let paywallName = null
    switch (this.$route.name) {
      case this.$ROUTER.NAME.PRICES:
        paywallName = this.$store.state.whitelabel.config?.paywall?.default
        break
      case this.$ROUTER.NAME.PRICES_PROMO:
        paywallName =
          this.$store.state.whitelabel.config?.paywall?.promoFree6Months
        this.useFullPrice = true
        this.useTrialsAsTitle = true
        break
      case this.$ROUTER.NAME.PRICES_CHARITABLE:
        paywallName =
          this.$store.state.whitelabel.config?.paywall?.charitablePaywall
        break
    }
    this.$store.dispatch('paywall/setPaywall', { paywallName: paywallName })
  },
}
</script>

<style scoped></style>
