<template>
  <div
    ref="clickOutside"
    class="c-form-autocomplete autocomplete clickOutside_qdsqw21"
  >
    <input
      @keydown="onKeydown"
      @focus="onFocus"
      @click="onFocus"
      :placeholder="placeholder"
      v-model="inputValue"
      ref="input"
      name="dropdown"
      class="form__control text text-md"
      autocomplete="autocomplete_off_hack_xfr4!k"
    />
    <div
      v-show="toShowList"
      class="c-form-autocomplete__autocomplete-items clickOutside_qdsqw22"
      ref="items"
    >
      <div
        v-for="(filteredItem, index) in filteredItems"
        :key="index"
        @click="select(index)"
        :ref="setItemRef(index)"
        class="text text-md"
        :class="{
          'c-form-autocomplete__autocomplete-active': currentFocus === index,
        }"
      >
        <span>{{ filteredItem }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const CONSTANTS = {
  LOCAL: {
    MAX_ITEMS: 6,
  },
}

export default {
  name: 'FormAutocomplete',

  data() {
    return {
      currentFocus: 0,
      currentTopFocus: 0,
      currentBottomFocus: CONSTANTS.LOCAL.MAX_ITEMS - 1,
      inputValue: '',
      toShowList: false,
      defaultValue: '',
      switchSelect: true,
      xPosition: 0,
      yPosition: 0,
      itemRefs: [],
    }
  },

  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    filteredItems: {
      get() {
        const res = this.items.reduce(
          (acc, item) => {
            // first, we add beginning coincidences
            if (
              (item.title ?? item)
                .toLowerCase()
                .indexOf(this.inputValue.toLowerCase()) === 0
            ) {
              acc.first.push(item.title ?? item)
            } // then, we add other coincidences
            else if (
              (item.title ?? item)
                .toLowerCase()
                .includes(this.inputValue.toLowerCase()) ||
              (item.aliases &&
                item.aliases.find((localized) =>
                  localized
                    .toLowerCase()
                    .includes(this.inputValue.toLowerCase())
                ))
            ) {
              acc.second.push(item.title ?? item)
            } // finally, the rest countries
            else {
              acc.third.push(item.title ?? item)
            }
            return acc
          },
          { first: [], second: [], third: [] }
        )

        return Array.prototype.concat(res.first, res.second, res.third)
      },
    },
  },

  watch: {
    inputValue() {
      this.currentFocus = 0
    },
    currentFocus(val) {
      if (val < this.currentTopFocus) {
        this.currentTopFocus = val
        this.currentBottomFocus = val + (CONSTANTS.LOCAL.MAX_ITEMS - 1)
      }
      if (val > this.currentBottomFocus) {
        this.currentBottomFocus = val
        this.currentTopFocus = val - (CONSTANTS.LOCAL.MAX_ITEMS - 1)
      }
      this.$nextTick(() => {
        this.$refs.items.scrollTo(
          0,
          this.itemRefs[this.currentTopFocus].offsetTop
        )
      })
    },
  },

  mounted() {
    document.body.appendChild(this.$refs.items)
    this.$nextTick(() => {
      document.addEventListener('touchstart', this.onTouchStart, false)
      document.addEventListener('touchend', this.onTouchEnd, false)
      document.addEventListener('touchcancel', this.onTouchCancel, false)
      document.addEventListener('click', this.onClick, false)
    })
  },
  unmounted() {
    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('touchcancel', this.onTouchCancel)
    document.removeEventListener('click', this.onClick)
  },

  methods: {
    setItemRef(index) {
      return (el) => {
        if (el && !this.itemRefs[index]) {
          this.itemRefs[index] = el
        }
      }
    },
    onKeydown(e) {
      switch (e.keyCode) {
        case 40:
          if (this.currentFocus < this.filteredItems.length - 1) {
            this.currentFocus++
          }
          break
        case 38:
          if (this.currentFocus > 0) {
            this.currentFocus--
          }
          break
        case 13:
          this.select(this.currentFocus)
          break
      }
    },

    onTouchStart(e) {
      this.clientY = e.changedTouches[0].clientY
      this.clientX = e.changedTouches[0].clientX
      this.radius = Math.max(
        (e.changedTouches[0].radiusX + e.changedTouches[0].radiusY) / 2,
        30
      )
    },

    onTouchEnd(e) {
      if (
        this.clientY &&
        this.clientX &&
        this.radius &&
        (Math.abs(this.clientY - e.changedTouches[0].clientY) > this.radius ||
          Math.abs(this.clientX - e.changedTouches[0].clientX) > this.radius)
      ) {
        return
      }
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes('clickOutside_qdsqw21') &&
          !Array.from(element.classList).includes('clickOutside_qdsqw22')
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.onClickOutside()
      }
    },

    onTouchCancel() {
      this.onClickOutside()
    },

    onClick(e) {
      let element = e.target
      let outside = true
      while (element) {
        outside =
          !Array.from(element.classList).includes('clickOutside_qdsqw21') &&
          !Array.from(element.classList).includes('clickOutside_qdsqw22')
        if (!outside || element.tagName === 'BODY') {
          break
        }
        element = element.parentNode
      }

      if (outside) {
        this.onClickOutside()
      }
    },

    onClickOutside() {
      if (this.inputValue) {
        this.select(this.currentFocus)
      } else {
        this.selectByName(this.defaultValue)
      }
    },

    onFocus() {
      if (this.switchSelect) {
        this.$refs.input.select()
        this.switchSelect = false
      }
      this.showList()
    },

    closeList() {
      this.toShowList = false
      this.switchSelect = true
    },

    showList() {
      this.setData()
      this.toShowList = true
      this.$nextTick(() => {
        this.$refs.items.scrollTo(0, 0)
      })
    },

    setData() {
      this.setItemsStyles()
    },

    setItemsStyles() {
      let element = this.$refs.input
      let [xPosition, yPosition] = [0, 0]
      while (element) {
        xPosition +=
          element.offsetLeft - element.scrollLeft + element.clientLeft
        yPosition += element.offsetTop - element.scrollTop + element.clientTop
        element = element.offsetParent
      }

      this.$refs.items.style.top = `${
        yPosition + this.$refs.input.clientHeight + 2
      }px`
      this.$refs.items.style.left = `${xPosition}px`
      this.$refs.items.style.width = `${this.$refs.input.clientWidth + 2}px`
    },

    select(index) {
      this.inputValue = this.filteredItems[index] || this.defaultValue
      this.$emit('selectItem', this.inputValue)
      this.closeList()
    },

    selectByName(name) {
      if (!name) return
      const inputItem = this.items.find((item) =>
        item.title
          ? item.title.toLowerCase() === name.toLowerCase()
          : item.toLowerCase() === name.toLowerCase()
      )
      this.inputValue = inputItem?.title || inputItem || ''
      this.defaultValue = this.inputValue
      this.$emit('selectItem', this.inputValue)
      this.closeList()
    },
  },
}
</script>

<style scoped></style>
