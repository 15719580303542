<template>
  <form-autocomplete
    ref="formAutocomplete"
    :items="preparedCountries"
    :placeholder="'Country'"
    @selectItem="onSelectCountry"
  ></form-autocomplete>
</template>

<script>
import FormAutocomplete from '@/components/FormAutocomplete'

export default {
  name: 'SelectCountry',

  components: {
    FormAutocomplete,
  },

  data() {
    return {
      IP: null,
    }
  },

  computed: {
    preparedCountries: {
      get() {
        return this.countries
          ? Object.entries(this.countries).map(([key, value]) => {
              return {
                title: key,
                aliases: Object.values(value.localizedTitles),
              }
            })
          : []
      },
    },
    selectedCountry: {
      get() {
        return this.$store.state.country.selectedCountry
      },
    },
    isDataReady: {
      get() {
        return Boolean(this.countries && this.locationCountryCode)
      },
    },
    locationCountryCode: {
      get() {
        return this.$store.state.user.location?.countryCode
      },
    },
    countries: {
      get() {
        return this.$store.state.country.list
      },
    },
  },

  watch: {
    isDataReady(newValue, pevValue) {
      if (newValue === true && pevValue === false) {
        this.initData()
      }
    },
    selectedCountry(newData) {
      this.$refs.formAutocomplete.selectByName(newData.code)
    },
  },

  mounted() {
    if (this.isDataReady) {
      this.initData()
    }
  },

  methods: {
    initData() {
      this.$nextTick(() => {
        const country = Object.entries(this.countries).find(
          (country) => country[1].code === this.locationCountryCode
        )
        let name = country ? country[0] : Object.keys(this.countries)[0]
        this.$refs.formAutocomplete.selectByName(name)
      })
    },

    onSelectCountry(countryName) {
      if (this.$store.state.country.selectedCountry?.code === countryName) {
        return
      }
      if (!this.countries[countryName]) {
        return
      }
      this.$store.dispatch('country/setSelectedCountry', {
        code: countryName,
        merchants: this.countries[countryName].merchants,
        localizedTitles: this.countries[countryName].localizedTitles,
      })
      this.$store.dispatch(
        'user/setCountryCode',
        this.countries[countryName].code
      )
    },
  },
}
</script>
