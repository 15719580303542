<template>
  <section class="user-has-account-section__content text-center">
    <h2 class="text user-has-account-section__plan mb-2 title title-md">
      {{ $t('web.current-plan') }}:
      <span class="color-main">{{ tariffName }}</span>
    </h2>
    <p
      v-if="nextPayDate"
      class="text user-has-account-section__debiting_date text text-lg"
    >
      {{ $t('web.prices_date-of-next-payment') }}:
      {{ $moment(nextPayDate).format('LL') }}
    </p>
    <p
      v-else-if="$store.getters['user/payedUntil']"
      class="text user-has-account-section__debiting_date text text-lg"
    >
      {{ $t('web.active_until') }}
      {{ $moment($store.getters['user/payedUntil']).format('LL') }}
    </p>
    <p class="user-has-account-section__text text mt-3 text text-md">
      {{ $t('web.prices_user-has-account_how-to-change-plan_1') }}
      <c-link
        :to="{ name: $ROUTER.NAME.ACCOUNT.BILLING }"
        nowrap
        class="text text-md"
        :label="$t('web.prices_user-has-account_how-to-change-plan_2')"
      />
      {{ howToChangePlan3 }}.
    </p>
  </section>
</template>

<script>
import { addSpace } from '@/utils/text'
import CLink from '@/components/Link'

export default {
  name: 'UserHasSubscription',

  components: {
    CLink,
  },

  computed: {
    nextPayDate: {
      get() {
        return this.$store.getters['user/nextPayDate'] || ''
      },
    },
    tariffName: {
      get() {
        return (
          this.$store.getters['user/currentPlans']?.find(
            (plan) => plan.subscription.isActive
          )?.name || ''
        )
      },
    },
    howToChangePlan3: {
      get() {
        const str = this.$t('web.prices_user-has-account_how-to-change-plan_3')
        return addSpace('left', str, str.indexOf(',') === 0)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.user-has-account-section {
  margin-bottom: 128px;

  &__content {
    font-weight: bold;
  }

  &__text {
    font-weight: 500;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__plan {
    margin: 0;
  }

  &__debiting_date {
    margin: 0;
  }
}
</style>
