<template>
  <form
    :action="url"
    :method="method"
    ref="form"
    @submit="preventSubmit"
    class="merchant text-center"
  >
    <input
      v-for="(input, index) in inputList"
      :key="index"
      type="hidden"
      :name="input.name"
      :value="input.value"
    />
    <c-button
      ref="submit"
      :value="value"
      :size="size"
      :disabled="disabled"
      :loading="loading"
    ></c-button>
  </form>
</template>

<script>
import CButton from './Button'
export default {
  name: 'FormButton',
  components: {
    CButton,
  },
  data() {
    return {
      toSubmit: false,
      loading: false,
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '#',
    },
    method: {
      type: String,
      default: 'get',
    },
    size: {
      type: String,
      default: 'md', // md, lg
    },
    beforeSubmit: {
      type: Function,
      default: () => new Promise((resolve) => resolve()),
    },
    insteadOfSubmit: {
      type: [Function, null],
      default: null,
    },
  },

  computed: {
    inputList: {
      get() {
        return Object.entries({ ...this.params }).map((input) => ({
          name: input[0],
          value: input[1],
        }))
      },
    },
  },

  methods: {
    preventSubmit(event) {
      if (this.toSubmit) {
        this.toSubmit = false
        return
      }

      event?.preventDefault()

      this.loading = true
      this.beforeSubmit()
        .then(() => {
          if (this.insteadOfSubmit) {
            this.insteadOfSubmit()
          } else {
            this.toSubmit = true
            this.$refs.form.submit()
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped></style>
