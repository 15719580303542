<template>
  <section class="plan">
    <div>
      <div class="row">
        <div class="col">
          <div class="plan__title">
            <p class="plan__step text text-xs">{{ $t('web.prices_Step') }} 1</p>
            <h2 v-if="title" class="title title-lg">
              {{ title }}
            </h2>
          </div>
          <ul class="no-style plan__list plan__list_is-desktop hide-mobile">
            <li
              v-for="(tariff, index) in tariffs"
              :key="index"
              class="plan__item"
            >
              <div
                class="plan__card tariff plan_{{ tariff.id }}"
                :class="{
                  plan__card_disabled: isDisabled,
                  plan__card_active: currentTariffIndex === index,
                }"
                @click="!isDisabled && onTariffClick(tariff, index)"
              >
                <div class="plan__card-content">
                  <div class="plan__card-label">
                    <div class="text text-bold text-lg">
                      {{
                        useTrialsAsTitle && tariff.trialDays
                          ? $t('web.period-for-free', {
                              period: daysInYears(tariff.trialDays),
                            })
                          : tariff.title
                      }}
                    </div>
                    <div
                      v-if="tariff.trialDays && !useTrialsAsTitle"
                      class="color-main text text-bold text-lg"
                    >
                      {{
                        $t('web.prices_trial-days', { count: tariff.trialDays })
                      }}
                    </div>
                    <div
                      v-else-if="tariff.trialDays && useTrialsAsTitle"
                      class="text text-lg plan__card_then"
                    >
                      {{ $t('web.then') }}
                    </div>
                  </div>
                  <div class="plan__card-info">
                    <div class="plan__card-price text">
                      <div class="currency text-lg">$</div>
                      <div class="value text-3xl">
                        {{
                          tariff[
                            useFullPrice ? 'price' : 'pricePerMonth'
                          ].toFixed(2)
                        }}
                      </div>
                      <div class="time text-lg">
                        /{{
                          useFullPrice
                            ? $t(`web.${tariff.period.toLowerCase()}`)
                            : $t('web.month')
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="plan__card-description">
                    <div class="plan__card-stoke">
                      <div class="text text-bold text-xs">
                        {{ $t('web.prices_save') }}
                        {{ tariff.discountPercent }}%
                      </div>
                    </div>
                    <div class="text text-xs">
                      <span
                        v-if="
                          tariff.oldPrice && tariff.oldPrice !== tariff.price
                        "
                        class="color-salmon text-line-through"
                        >${{ tariff.oldPrice.toFixed(2) }}</span
                      >
                      ${{ tariff.price.toFixed(2) }}
                      {{
                        `${$t('web.prices_billedEvery')} ${$t(
                          `web.${tariff.period.toLowerCase()}`
                        )}`
                      }}
                    </div>
                  </div>
                </div>
                <div v-if="index === 0" class="plan__card-percent-background">
                  %
                </div>
                <div
                  v-if="index === 0"
                  class="plan__card-popular-label text-xs"
                >
                  {{ $t('web.prices_the-most-popular') }}
                </div>
                <div class="plan__checkbox">
                  <icon
                    v-if="currentTariffIndex === index"
                    :name="$t('web.icon_check-circle')"
                    color="var(--main)"
                    fill
                    width="24"
                    height="24"
                  >
                    <checkCircle />
                  </icon>
                  <icon
                    v-if="currentTariffIndex !== index"
                    :name="$t('web.icon_check-circle-empty')"
                    width="24"
                    height="24"
                  >
                    <checkCircleEmpty />
                  </icon>
                </div>
              </div>
              <div
                class="plan__info"
                :class="{
                  plan__info_active: currentTariffIndex === index,
                  plan__info_main: index === 0,
                  plan__info_second: index !== 0,
                }"
              >
                <div class="plan__info-icon">
                  <icon
                    v-if="index === 0"
                    :name="$t('web.icon_like')"
                    color="white"
                    fill
                    width="24"
                    height="24"
                  >
                    <like />
                  </icon>
                  <icon
                    v-else
                    :name="$t('web.icon_info')"
                    color="white"
                    fill
                    width="24"
                    height="24"
                  >
                    <info />
                  </icon>
                </div>
                <div class="plan__info-text text text-md">
                  {{ $t('web.prices_the-1-year-is-better') }}
                </div>
              </div>
            </li>
          </ul>
          <ul
            class="
              no-style
              plan__list plan__list_is-mobile
              hide-tablet hide-desktop
            "
          >
            <li
              v-for="(tariff, index) in tariffs"
              :key="index"
              class="plan__item"
            >
              <div
                class="plan__card tariff plan_{{ tariff.id }}"
                :class="{
                  plan__card_disabled: isDisabled,
                  plan__card_active: currentTariffIndex === index,
                }"
                @click="!isDisabled && onTariffClick(tariff, index)"
              >
                <div class="plan__card-content">
                  <div class="d-flex align-items-center">
                    <div
                      v-if="currentTariffIndex === index"
                      class="plan__checkbox plan__checkbox_active"
                    >
                      <icon
                        :name="$t('web.icon_check-circle')"
                        color="var(--main)"
                        fill
                        :resize="false"
                        width="16"
                        height="16"
                      >
                        <checkCircle />
                      </icon>
                    </div>
                    <div
                      v-if="currentTariffIndex !== index"
                      class="plan__checkbox plan__checkbox_inactive"
                    >
                      <icon
                        :name="$t('web.icon_check-circle-empty')"
                        :resize="false"
                        width="16"
                        height="16"
                      >
                        <checkCircleEmpty />
                      </icon>
                    </div>
                    <div class="plan__card-info">
                      <div class="plan__card-label">
                        <div class="text text-md text-bold">
                          {{
                            useTrialsAsTitle && tariff.trialDays
                              ? $t('web.period-for-free', {
                                  period: daysInYears(tariff.trialDays),
                                }) +
                                ', ' +
                                $t('web.then')
                              : tariff.title
                          }}
                        </div>
                      </div>
                      <div class="plan__card-description text text-xs">
                        {{ $t('web.prices_Billed') }}
                        <span
                          v-if="
                            tariff.oldPrice && tariff.oldPrice !== tariff.price
                          "
                          class="plan__card-discount-price-old"
                          >$<span class="sum-price">{{
                            tariff.oldPrice.toFixed(2)
                          }}</span></span
                        >
                        ${{ tariff.price.toFixed(2) }}
                        {{ $t('web.prices_every') }}
                        {{ $t(`web.${tariff.period.toLowerCase()}`) }}
                        <span
                          v-if="
                            tariff.pricePerMonth !== tariff.price &&
                            !useFullPrice
                          "
                          >${{ tariff.pricePerMonth.toFixed(2) }}/{{
                            $t('web.month')
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="plan__card-price">
                    <div class="value text text-2xl">
                      ${{
                        useFullPrice
                          ? tariff.price.toFixed(2)
                          : tariff.pricePerMonth.toFixed(2)
                      }}
                    </div>
                    <div class="time text text-sm">
                      /{{
                        useFullPrice
                          ? $t(`web.period_mini_${tariff.period.toLowerCase()}`)
                          : $t(`web.period_mini_month`)
                      }}
                    </div>
                  </div>
                </div>
                <div v-if="index === 0" class="plan__card-percent-background">
                  %
                </div>
              </div>
              <div v-if="index === 0" class="plan__card-discount-block">
                {{ $t('web.prices_save') }}
                {{ tariff.discountPercent }}%
              </div>
              <div
                class="plan__info"
                :class="{
                  plan__info_active: currentTariffIndex === index,
                  plan__info_main: index === 0,
                  plan__info_second: index !== 0,
                }"
              >
                <div class="plan__info-icon">
                  <icon
                    v-if="index === 0"
                    :name="$t('web.icon_like')"
                    color="white"
                    fill
                    :resize="false"
                    width="16"
                    height="16"
                  >
                    <like />
                  </icon>
                  <icon
                    v-else
                    :name="$t('web.icon_info')"
                    color="white"
                    fill
                    width="16"
                    height="16"
                  >
                    <info />
                  </icon>
                </div>
                <div class="plan__info-text text text-sm">
                  {{ $t('web.prices_the-1-year-is-better') }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '@/components/Icon'
import checkCircle from '@/assets/image/icons/svg/checkCircle.svg'
import checkCircleEmpty from '@/assets/image/icons/svg/checkCircleEmpty.svg'
import like from '@/assets/image/icons/svg/like.svg'
import info from '@/assets/image/icons/svg/info.svg'
import gtag from '@/utils/gtag'
import eventBus from '@/utils/EventBus'
import { EVENTS } from '@/CONSTANTS'
import { convertDaysToYears } from '../../../../utils/tariff'

export default {
  name: 'Plans',
  components: {
    Icon,
    checkCircle,
    checkCircleEmpty,
    like,
    info,
  },
  data() {
    return {
      currentTariffIndex: 0,
      currentTariff: null,
    }
  },
  props: {
    useFullPrice: {
      type: Boolean,
      default: false,
    },
    useTrialsAsTitle: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    currentTariff() {
      this.$store.dispatch('paywall/setCurrentTariff', {
        currentTariff: this.currentTariff,
      })
    },
    tariffs(value) {
      if (Array.isArray(value)) {
        this.currentTariff = value[this.currentTariffIndex]
      }
    },
  },
  computed: {
    tariffs: {
      get() {
        return this.$store.getters['paywall/paywallTariffs'].sortBy
          .period()
          .desc()
      },
    },
    isDisabled: {
      get() {
        return (
          this.$store.getters['user/token'] &&
          this.$store.state.user.hasSubscription
        )
      },
    },
    title: {
      get() {
        return this.$t('web.prices_choose-plan')
      },
    },
  },
  mounted() {
    if (this.tariffs?.length > 0 && !this.currentTariff) {
      this.$nextTick(() => {
        this.currentTariff = this.tariffs[this.currentTariffIndex]
      })
    }
  },
  methods: {
    onTariffClick(tariff, index) {
      this.currentTariffIndex = index
      this.currentTariff = tariff
      gtag('BuyButtonStep1')
      eventBus.$emit(EVENTS.TARIFF.CLICKED)
    },
    daysInYears(days) {
      return convertDaysToYears({ days })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'index';
</style>
