<template>
  <div class="col-12 col-md-7">
    <div class="purchase__payment-list">
      <div
        v-for="(paymentMethod, index) in paymentMethods"
        :key="index"
        class="purchase__payment-item"
        :class="`payment__${paymentMethod.NAME}`"
      >
        <div
          class="purchase__payment-item-title"
          :class="{
            opened: index === openingExpansionBlockIndex,
          }"
          @click="openExpansionBlock(index)"
          :data-value="paymentMethod.NAME"
        >
          <div class="purchase__payment-icon-list">
            <icon
              :name="$t('web.icon_down')"
              color="var(--main)"
              fill
              class="purchase__payment-icon"
              :class="{
                'purchase__payment-icon--reverse':
                  index === openingExpansionBlockIndex,
              }"
              width="20"
              height="20"
            >
              <down />
            </icon>
          </div>
          <span class="text text-lg">{{ paymentMethod.uiName }}</span>
          <span class="purchase__payment-images">
            <img
              v-for="(image, index) in paymentMethod.images"
              :key="index"
              :src="image.src"
              :alt="image.name"
              class="purchase__payment-images-item"
            />
          </span>
        </div>

        <div
          class="purchase__payment-content"
          :class="{
            opened: index === openingExpansionBlockIndex,
          }"
          :ref="setPaymentContentRef(index)"
        >
          <div
            class="purchase__payment-content-wrapper"
            :ref="setPaymentContentWrapperRef(index)"
          >
            <hr class="purchase__payment-content-hr" />
            <div class="purchase__layout">
              <div class="purchase__header">
                <div class="purchase__info">
                  <div
                    v-if="currentTariff?.discount"
                    class="purchase__info-item tariffsDefault_discount-block"
                  >
                    <div
                      class="
                        purchase__info-label
                        text text-lg
                        tariffsDefault_title
                      "
                    >
                      {{ currentTariff?.title }}
                    </div>
                    <div class="purchase__info-data text text-lg">
                      <div>
                        <span
                          class="purchase__info-label_num tariffsDefault_first"
                        >
                          {{ currentTariff.currencySymbol }}{{ currentTariff?.oldPrice }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="currentTariff?.discount"
                    class="purchase__info-item tariffsDefault_discount-block"
                  >
                    <div class="purchase__info-label text text-lg">
                      {{ $t('web.prices_discount') }}
                    </div>
                    <div class="purchase__info-data text text-lg">
                      <div>
                        <span
                          class="
                            purchase__info-label_num
                            tariffsDefault_discount
                          "
                        >
                          -{{ currentTariff.currencySymbol }}{{ currentTariff?.discount }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="currentTariff?.trialDays"
                    class="purchase__info-item tariffsDefault_discount-block"
                  >
                    <div class="purchase__info-label text text-lg">
                      {{ $t('web.prices_trial') }}
                    </div>
                    <div class="purchase__info-data text text-lg">
                      <div>
                        <span
                          class="
                            purchase__info-label_num
                            tariffsDefault_discount
                          "
                        >
                          {{
                            currentTariff?.trialDays > 7
                              ? daysInMonths(currentTariff?.trialDays)
                              : $t('web.days', {
                                  count: currentTariff?.trialDays,
                                })
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="purchase__info-item purchase__info-item_main">
                    <div class="purchase__info-label text text-lg">
                      {{ $t('web.prices_order-total') }}:
                    </div>
                    <div class="purchase__info-data text text-lg">
                      <div>
                        <span
                          class="purchase__info-label_num tariffsDefault_sum"
                        >
                        {{ currentTariff.currency }} {{ currentTariff.currencySymbol }}{{ currentTariff?.price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="purchase__input">
                <div v-if="!token" class="col-12 pr-md-0 p-0">
                  <div class="form purchase__input-form">
                    <div class="d-flex justify-content-between flex-wrap">
                      <span
                        class="
                          purchase__input-title
                          text text-lg
                          p-0
                          align-items-center
                          d-flex
                          col-12 col-md-4
                        "
                      >
                        {{ $t('web.prices_email') }}
                      </span>
                      <c-input
                        :ref="setRef(CONSTANTS.LOCAL.REFS.EMAIL, index)"
                        v-model="cEmail"
                        autocomplete="on"
                        class="p-0 col-12 col-md-8"
                        :type="CONSTANTS.INPUT.META.TYPE.EMAIL"
                        :view="CONSTANTS.INPUT.META.VIEW.INLINE"
                        :validateMethods="
                          validateMethods[CONSTANTS.LOCAL.REFS.EMAIL]
                        "
                      ></c-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="purchase__input">
                <div class="col-12 pr-md-0 p-0">
                  <div class="form purchase__input-form">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="col-4 col-12 col-md-4 d-flex">
                        <span
                          class="
                            purchase__input-title
                            text text-lg
                            p-0
                            align-items-center
                          "
                        >
                          {{ $t('web.prices_country') }}
                        </span>
                      </div>
                      <div class="col-12 col-md-8">
                        <select-country></select-country>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="purchase__footer">
                <div class="text-center">
                  <form-button
                    :params="formButtonParams"
                    :method="formButtonMethod"
                    :url="formButtonURL"
                    :insteadOfSubmit="formButtonInsteadOfSubmit"
                    :beforeSubmit="formButtonBeforeSubmit"
                    :value="
                      currentTariff?.trialDays
                        ? $t('web.tryFree')
                        : $t('web.prices_pay')
                    "
                    size="lg"
                    class="mt-4"
                    :data-merchant="merchant"
                  ></form-button>
                </div>

                <div class="purchase__policy text-center">
                  <p class="text text-xs" data-typograf>
                    {{ getPrivacyStr(1) }}
                    <c-link
                      :to="{ name: $ROUTER.NAME.TERMS }"
                      blank
                      nowrap
                      bold
                      underline
                      :label="getPrivacyStr(2)"
                      color="black"
                    />
                    {{ getPrivacyStr(3) }}
                    <c-link
                      :to="{ name: $ROUTER.NAME.PRIVACY_POLICY }"
                      color="black"
                      blank
                      nowrap
                      bold
                      underline
                      :label="getPrivacyStr(4)"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTANTS from '@/CONSTANTS'
import CInput from '@/components/Input'
import FormButton from '@/components/FormButton'
import CLink from '@/components/Link'
import Icon from '@/components/Icon'
import Down from '@/assets/image/icons/svg/down.svg'
import SelectCountry from './../../components/SelectCountry'
import gtag from '@/utils/gtag'
import { clog } from '@/utils/logger'
import METHODS from '@/utils/Validator/METHODS'
import * as Sentry from '@sentry/browser'
import { loadStripe } from '@stripe/stripe-js/pure'
import { chooseOneFromArrayRandomly } from '@/utils/array'
import { convertDaysToMonths } from '../../../../utils/tariff'

const CryptoJS = require('crypto-js')

const _CONSTANTS = {
  REFS: {
    EMAIL: 'email',
  },
}

export default {
  name: 'PaymentMethods',
  components: {
    CInput,
    SelectCountry,
    FormButton,
    Icon,
    Down,
    CLink,
  },
  data() {
    this.stripePublishableKey = process.env.VUE_APP__STRIPE_PUBLIC_KEY
    this.CONSTANTS = Object.assign({}, CONSTANTS, { LOCAL: _CONSTANTS })
    return {
      cEmail: this.$store.state.user.email || '',
      stripeSessionId: null,
      stripe: null,
      formButtonParams: {},
      formButtonMethod: undefined,
      formButtonURL: undefined,
      openingExpansionBlockIndex: 0,
      openedExpansionBlockIndex: 0,
      paymentContentWrapperRefs: [],
      paymentContentRefs: [],
      timer: null,
      iRefs: {},
    }
  },

  watch: {
    '$store.state.user.email': function (value) {
      this.cEmail = value
    },
    merchant(value) {
      clog('Смена процессинга на', value)
    },
    currentTariff() {
      this.iRefs = {}
    },
    paymentMethods() {
      this.iRefs = {}
    },
    currentPaymentMethod(value) {
      if (
        !value &&
        this.paymentMethods.length > 0 &&
        typeof this.openingExpansionBlockIndex === 'number'
      ) {
        this.openExpansionBlock(0)
      }
    },
  },

  mounted() {
    this.initStripe()
    clog('Текущий процессинг', this.merchant)
  },

  computed: {
    token: {
      get() {
        return this.$store.getters['user/token']
      },
    },
    paymentMethods: {
      get() {
        return Object.values(this.CONSTANTS.PAYMENT.PAYMENT_METHOD).reduce(
          (acc, { NAME, ...props }) => {
            if (!this.isPermittedForPaymentMethod({ NAME, ...props })) {
              return acc
            }
            const newProps = {}
            switch (NAME) {
              case this.CONSTANTS.PAYMENT.PAYMENT_METHOD.INTERNET_WALLET.NAME:
                newProps.uiName = this.$t('web.price_internet-wallet')
                newProps.images = [
                  {
                    name: 'VISA',
                    src: require('@/assets/image/paymentMethods/visa.png'),
                  },
                  {
                    name: 'Mastercard',
                    src: require('@/assets/image/paymentMethods/mastercard.png'),
                  },
                  {
                    name: 'MIR',
                    src: require('@/assets/image/paymentMethods/mir.png'),
                  },
                  {
                    name: 'QIWI',
                    src: require('@/assets/image/paymentMethods/qiwi.png'),
                  },
                  {
                    name: 'WebMoney',
                    src: require('@/assets/image/paymentMethods/webmoney.png'),
                  },
                  {
                    name: 'YooMoney',
                    src: require('@/assets/image/paymentMethods/yoomoney.png'),
                  },
                ]
                break
              case this.CONSTANTS.PAYMENT.PAYMENT_METHOD.CRYPTOCURRENCY.NAME:
                newProps.uiName = this.$t('web.price_cryptocurrency')
                newProps.images = [
                  {
                    name: 'Etherium',
                    src: require('@/assets/image/paymentMethods/etherium.png'),
                  },
                  {
                    name: 'Bitcoin',
                    src: require('@/assets/image/paymentMethods/bitcoin.png'),
                  },
                ]
                break
              case this.CONSTANTS.PAYMENT.PAYMENT_METHOD.CARD.NAME:
              default:
                newProps.uiName = this.$t('web.price_card')
                newProps.images = [
                  {
                    name: 'Mastercard',
                    src: require('@/assets/image/paymentMethods/mastercard.png'),
                  },
                  {
                    name: 'VISA',
                    src: require('@/assets/image/paymentMethods/visa.png'),
                  },
                ]
                break
            }
            acc.push({
              NAME,
              ...props,
              ...newProps,
            })
            return acc
          },
          []
        )
      },
    },
    permittedMerchants: {
      get() {
        const paymentMethodMerchants =
          Object.values(this.CONSTANTS.PAYMENT.PAYMENT_METHOD).find(
            ({ NAME }) =>
              !this.currentPaymentMethod ||
              NAME === this.currentPaymentMethod.NAME
          )?.MERCHANTS || []
        const enabledMerchants = this.$store.getters['paywall/enabledMerchants']
        const tariffMerchants = this.currentTariff?.merchants || []
        const countryMerchants =
          this.$store.state.country.selectedCountry?.merchants ||
          enabledMerchants

        const allMerchants = [
          paymentMethodMerchants,
          enabledMerchants,
          tariffMerchants,
          countryMerchants,
        ]

        const allowedMerchants = Object.values(
          this.CONSTANTS.PAYMENT.MERCHANT
        ).filter((MERCHANT_NAME) => {
          return allMerchants.every((merchants) =>
            merchants.includes(MERCHANT_NAME)
          )
        })

        return allowedMerchants
      },
    },
    merchant: {
      get() {
        return (
          chooseOneFromArrayRandomly(this.permittedMerchants) ||
          this.$store.state.whitelabel.config?.DEFAULT_MERCHANT
        )
      },
    },
    payApiName: {
      get() {
        return this.CONSTANTS.PAYMENT.PAY_API_NAME[this.merchant]
      },
    },
    currentTariff: {
      get() {
        return this.$store.state.paywall.currentPaywallTariff
      },
    },
    currentPaymentMethod: {
      get() {
        return this.paymentMethods?.[this.openingExpansionBlockIndex]
      },
    },
    formButtonInsteadOfSubmit: {
      get() {
        switch (this.merchant) {
          case this.CONSTANTS.PAYMENT.MERCHANT.STRIPE:
            return async () => {
              this.stripe.redirectToCheckout({
                sessionId: this.stripeSessionId,
              })
            }
          default:
            return null
        }
      },
    },

    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.EMAIL]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.email')],
            METHODS.IS_EMAIL,
          ],
        }
      },
    },
  },

  beforeMount() {
    if (!this.token) {
      this.cEmail = this.getEmailFromParams() || ''
    }
  },

  methods: {
    async initStripe() {
      if (this.stripePublishableKey && this.stripePublishableKey !== 'NULL') {
        this.stripe = await loadStripe(this.stripePublishableKey)
      }
    },
    daysInMonths(days) {
      return convertDaysToMonths({ days })
    },
    isPermittedForPaymentMethod(paymentMethod) {
      const paymentMethodMerchants = paymentMethod.MERCHANTS
      const productMerchants =
        this.$store.state.whitelabel.config?.merchants || []
      const tariffMerchants = this.currentTariff?.merchants || []
      const countryMerchants =
        this.$store.state.country.selectedCountry?.merchants || productMerchants
      const allMerchants = [
        paymentMethodMerchants,
        productMerchants,
        tariffMerchants,
        countryMerchants,
      ]

      return (
        Object.values(this.CONSTANTS.PAYMENT.MERCHANT).filter(
          (MERCHANT_NAME) => {
            return allMerchants.every((merchants) =>
              merchants.includes(MERCHANT_NAME)
            )
          }
        ).length > 0
      )
    },
    setPaymentContentRef(index) {
      return (el) => {
        if (el && !this.paymentContentRefs[index]) {
          this.paymentContentRefs[index] = el
        }
      }
    },
    setPaymentContentWrapperRef(index) {
      return (el) => {
        if (el && !this.paymentContentWrapperRefs[index]) {
          this.paymentContentWrapperRefs[index] = el
        }
      }
    },
    setRef(refName, i) {
      return (el) => {
        this.iRefs[refName] = this.iRefs[refName] || []
        if (el) {
          this.iRefs[refName][i] = el
        }
      }
    },
    openExpansionBlock(index) {
      this.openingExpansionBlockIndex =
        this.openingExpansionBlockIndex === index &&
        typeof this.openingExpansionBlockIndex === 'number'
          ? null
          : index
      const expandedConetnt = this.paymentContentRefs[index]
      const expandedContentWrapper = this.paymentContentWrapperRefs[index]
      expandedConetnt.style.height = `${expandedContentWrapper.clientHeight}px`
      if (typeof this.openedExpansionBlockIndex === 'number') {
        const closedConetnt =
          this.paymentContentRefs[this.openedExpansionBlockIndex]
        const closedContentWrapper =
          this.paymentContentWrapperRefs[this.openedExpansionBlockIndex]
        closedConetnt.style.height = `${closedContentWrapper.clientHeight}px`
      }
      this.openedExpansionBlockIndex = this.openingExpansionBlockIndex
      setTimeout(() => {
        this.paymentContentRefs.forEach((_, i) => {
          if (i === index) {
            if (typeof this.openingExpansionBlockIndex !== 'number') {
              expandedConetnt.style.height = `0px`
            }
          } else {
            this.paymentContentRefs[i].style.height = `0px`
          }
        })
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.timer = null
          if (typeof this.openingExpansionBlockIndex === 'number') {
            expandedConetnt.style.height = 'auto'
          }
        }, 400) // TODO: время можно брать из стилей
      }, 0)
    },
    formButtonBeforeSubmit() {
      return new Promise((resolve, reject) => {
        const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
          if (this.iRefs[type]) {
            const ref = this.iRefs[type]
            if (Array.isArray(ref)) {
              acc.push(...ref)
            } else {
              acc.push(ref)
            }
          }
          return acc
        }, [])
        const promises = inputs.map((input) => input.validate())
        Promise.all(promises)
          .then(() => {
            this.$api.merchant[this.payApiName]({
              tariff_id: this.currentTariff?.id,
              email: this.cEmail,
              secret_key: this.currentTariff?.purchaseSecretKey,
            })
              .then((res) => {
                this.prepareFormButtonProps(res.data)
                this.$nextTick(() => {
                  this.gtagEmit()
                  if (res.data.user?.password) {
                    localStorage.setItem(
                      'password',
                      CryptoJS.AES.encrypt(
                        res.data.user.password,
                        this.$store.getters['config/pwdSecret']
                      ).toString()
                    )
                  }
                  if (res.data.user?.token) {
                    localStorage.setItem('token', res.data.user.token)
                  }
                  resolve()
                })
              })
              .catch((error) => {
                this.gtagEmit('errorWhenTryToGetMerchantParams')
                Sentry.captureMessage(
                  JSON.stringify({
                    error: 'errorWhenTryToGetMerchantParams',
                    email: this.cEmail,
                    message:
                      error.errors.data?.[0] ||
                      error.errors[_CONSTANTS.REFS.EMAIL]?.[0],
                  })
                )
                // Ужас. Нужно чтобы таких ифов не было
                if (error.errors.data) {
                  this.$store.dispatch('app/showDialog', {
                    title: error.title,
                    type: 'error',
                    description: error.errors.data[0],
                  })
                } else {
                  const componentsMap = {
                    [_CONSTANTS.REFS.EMAIL]: this.$refs[_CONSTANTS.REFS.EMAIL],
                  }
                  Object.entries(error.errors).forEach((err) => {
                    const ref = componentsMap[err[0]] // TODO сделать возможным ошибки без емейла
                    if (Array.isArray(ref)) {
                      ref.forEach((ref) => {
                        ref.setError(err[1][0]) // Поработать с ошибками. Добавить валидатор
                      })
                    } else {
                      ref.setError(err[1][0]) // Поработать с ошибками. Добавить валидатор
                    }
                  })
                }
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      })
    },
    getEmailFromParams() {
      return this.$route.query?.email
    },
    gtagEmit(event = 'BuyButtonStep2') {
      gtag(event)
    },
    prepareFormButtonProps(data) {
      switch (this.merchant) {
        case this.CONSTANTS.PAYMENT.MERCHANT.STRIPE: {
          this.stripeSessionId = data.session.id
          break
        }
        default: {
          let paramsFromUrl = {}
          if (data.url) {
            const url = new URL(data.url)
            const searchParams = new URLSearchParams(url.search)
            paramsFromUrl = Array.from(searchParams.entries()).reduce(
              (acc, [paramKey, paramValue]) => {
                acc[paramKey] = paramValue
                return acc
              },
              {}
            )
          }
          const paramsFromData = data.params || {}

          this.formButtonParams = Object.assign(paramsFromUrl, paramsFromData)
          this.formButtonMethod = data.method || null
          this.formButtonURL = data.url || null
        }
      }
    },
    getPrivacyStr(number) {
      switch (number) {
        case 1:
          return this.$t('web.prices_privacy_p-0')
        case 2:
          return this.$t('web.prices_privacy_p-1').replace('&nbsp;', '\xa0')
        case 3:
          return this.$t('web.prices_privacy_p-2')
        case 4:
          return this.$t('web.prices_privacy_p-3').replace('&nbsp;', '\xa0')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'index';
</style>
