import { clog } from './logger'
import store from '@/store'

export default (event) => {
  clog('GOOGLE ANALYTICS EVENT [START]:', event)
  if (
    !event ||
    process.env.VUE_APP__GOOGLE_ANAlYTICS_ID.toUpperCase().indexOf('UA-') !==
      0 ||
    !window.gtag ||
    store.state.app.isDeveloperMode
  ) {
    clog(
      'GOOGLE ANALYTICS EVENT [STOPPED AND CLOSED]:',
      event,
      'REASON:',
      !event
        ? 'NO EVENT'
        : process.env.VUE_APP__GOOGLE_ANAlYTICS_ID.toUpperCase().indexOf(
            'UA-'
          ) !== 0
        ? 'NO ENV VUE_APP__GOOGLE_ANAlYTICS_ID'
        : !window.gtag
        ? 'NO window.gtag'
        : store.state.app.isDeveloperMode
        ? 'YOU ARE DEVELOPER'
        : 'UNKNOWN'
    )
    return false
  }
  window.gtag('event', 'Action', {
    event_label: event,
    event_callback: function (data) {
      if (data === undefined) {
        throw new Error(
          `GOOGLE ANALYTICS EVENT [ERROR]: event Action ${event} returns undefined`
        )
      }
      clog('GOOGLE ANALYTICS EVENT [SUCCESS]:', event)
    },
  })
  return true
}
