<template>
  <section class="purchase">
    <div class="invisible-anchor" ref="purchaseBlock"></div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="purchase__title">
            <p class="purchase__step text text-xs">
              {{ $t('web.prices_Step') }} 2
            </p>
            <h2 class="title title-lg">
              {{ $t('web.prices_Chose-Payment-Method') }}
            </h2>
          </div>
        </div>
        <div class="col-12 purchase__container">
          <div class="row flex">
            <payment-methods></payment-methods>

            <div class="col-12 col-md-5 purchase__why-chose">
              <div class="purchase__why-chose-money-back">
                <img
                  v-if="
                    $store.state.paywall.currentPaywallTariff?.period ===
                    'month'
                  "
                  class="money-back__image"
                  :src="require('@/assets/image/features/money-back-7.png')"
                  alt=""
                />
                <img
                  v-else
                  class="money-back__image"
                  :src="require('@/assets/image/features/money-back-30.png')"
                  alt=""
                />
              </div>
              <h3 class="purchase__why-chose-title title title-md">
                {{
                  $t('web.prices_Why-choose-VPN', {
                    productName,
                  })
                }}
              </h3>
              <p class="purchase__why-chose-description-title text text-lg">
                {{
                  $t('web.prices_VPN-includes', {
                    productName,
                  })
                }}:
              </p>
              <ul class="no-style purchase__why-chose-description-list">
                <li
                  v-for="(feature, index) in features"
                  :key="index"
                  class="purchase__why-chose-description-item text text-md"
                >
                  <span class="purchase__why-chose-icon">
                    <icon
                      :name="$t('web.icon_check')"
                      color="var(--main)"
                      fill
                      width="20"
                      height="20"
                      stroke
                    >
                      <check />
                    </icon>
                  </span>
                  <span class="text text-sm">{{ feature.text }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PaymentMethods from './PaymentMethods'
import Icon from '@/components/Icon'
import Check from '@/assets/image/icons/svg/check.svg'
import eventBus from '@/utils/EventBus'
import { EVENTS } from '@/CONSTANTS'
export default {
  name: 'Purchase',
  components: {
    PaymentMethods,
    Check,
    Icon,
  },
  data() {
    return {
      productName: process.env.VUE_APP__PRODUCT_NAME,
    }
  },
  mounted() {
    eventBus.$on(EVENTS.TARIFF.CLICKED, () => {
      const myEl = this.$refs.purchaseBlock
      this.$smoothScroll({
        scrollTo: myEl,
      })
    })
  },
  unmounted() {
    eventBus.$off(EVENTS.TARIFF.CLICKED)
  },
  computed: {
    features: {
      get() {
        return [
          {
            text: this.$t('web.prices_includes-support'),
          },
          {
            text: this.$t('web.prices_includes-money-back', {
              numberOfDays:
                this.$store.state.paywall.currentPaywallTariff?.period ===
                'month'
                  ? 7
                  : 30,
            }),
          },
          {
            text: this.$t('web.prices_includes-security'),
          },
          {
            text: this.$t('web.prices_includes-no-logs'),
          },
        ]
      },
    },
  },
}
</script>

<style scoped lang="scss">
@import 'index';
.invisible-anchor {
  position: absolute;
  top: -50px;

  @media (max-width: 1023.98px) {
    top: -80px;
  }
}
.purchase {
  position: relative;
}
</style>
